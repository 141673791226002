.weeks {
  --flow-gap: 5em;
}

.bookmarks {
  --flow-gap: 3em;
}

.bookmark {
  display: flex;
  max-width: unset;
  gap: 2rem;
}

.bookmark_image {
  width: 100px;
  min-height: 100px;
  height: 100px;
  flex-shrink: 0;
  border-radius: 0.5rem;
  overflow: hidden;
  display: none;

  @include for-size(tablet-portrait-up) {
   display: unset;
  }
}

.bookmark .title {
  word-break: break-word;
}

.bookmark-description > p {
  grid-row: 1 / -1;
  max-width: 60ch;
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.meta {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: calc(var(--flow-gap, 1em) / 2)
}

.categories {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  align-items: flex-start;
}

.timestamps {
  text-align: end;
}