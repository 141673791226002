.backlinks {
  display: grid;
  grid-template-columns: repeat( auto-fill, minmax(15rem, 1fr) ) ;
  grid-gap: var(--flow-gap, 1em);
}

.backlink {
}

.links svg {
  height: 1em;
}