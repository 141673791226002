last-fm {
  display: grid;
  grid-gap: 0.75em;
}

last-fm h2, last-fm h3 {
  @extend %text--md;
  margin: 0;
}

last-fm .track {
display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 1em;
}

last-fm p {
  margin: 0;
}