@import "resume/experience";
@import "resume/skills";
@import "resume/chat";

:root {
  --grid-gap: 1em;

  @include for-size(tablet-landscape-up) {
    --grid-gap: 2em;
  }

  @media print {
    --grid-gap: 2em;
  }
}

.resume {
  display: flex;
  flex-direction: column;
  gap: var(--grid-gap);
}

.information {
  grid-area: information;
  display: grid;
  gap: var(--grid-gap);
  align-content: start;

  @include for-size(tablet-portrait-up) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  @include for-size(tablet-landscape-up) {
    grid-template-columns: unset;
    grid-template-rows: unset;
  }
}

@include for-size(tablet-portrait-up) {
  .description {
    grid-row: 1 / -1;
  }
}

@include for-size(tablet-landscape-up) {
  .description {
    grid-row: unset;
  }
}

.description > * + *, .skills > * + * {
  margin-block-start: 1em;
}


.description > p {
  max-width: 50ch;
}

.experience {
  grid-area: experience;
  display: flex;
  flex-direction: column;
  gap: var(--grid-gap);
}

.job-item__description {
  max-width: 55ch;
}

@media print {
  .resume {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-areas: "experience information";
    column-gap: calc(var(--grid-gap) * var(--text-scale-ratio));
  }

  .information {
    grid-template-columns: unset;
    grid-template-rows: unset;
  }

  .lets-chat {
    display: none;
  }
}

@include for-size(tablet-landscape-up) {
  .resume {
    display: grid;
    grid-template-columns: 5fr 3fr;
    grid-template-areas: "experience information";
  }

  //.information {
  //  flex-direction: column;
  //
  //  > * {
  //    flex-basis: unset;
  //    flex-grow: 0;
  //  }
  //}
}

.education {

@media print {
  page-break-inside: avoid;
}
}


