.posts {
  --flow-gap: 2em;
  list-style: none;
  padding: 0;
  margin: 0;
}

.post a {
  text-decoration: none;
}

.post-title {
  max-width: 25ch;
}

.post__tags {
  @extend %text--sm;
  display: flex;
  flex-wrap: wrap;
  gap: 1ch;
}
