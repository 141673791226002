.applications {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15em, 1fr));
  grid-gap: var(--flow-gap, 1em);
}

.application {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-gap: var(--flow-gap, 1em);
  padding: 0.5em 1em;
  border: 1px solid gray;
  border-radius: 0.5em;
}