.page-footer {
  position: sticky;
  bottom: 0;

  background: var(--color-body-bg);
  padding-block-start: 0.25rem;
  z-index: 99;

  border-top: 1px solid var(--color-primary);

  .social-media-list {
    display: flex;
    justify-content: center;
    gap: 3rem;
  }
}

.social-media-list {
  padding-inline-start: 0;
}

.social-media-list > li {
  margin-block-end: 1em;
  list-style-type: none;
}

.social-media-list > li .svg-icon {
  margin-inline-end: 0.6em;
  color: var(--color-text);
}

.social-media-list > li a::after {
  @media print {
    display: inline-block;
    content: attr(href);
    font-size: 0.8em;
    margin-inline-start: 0.5em;
  }
}

.social-media-list > li a {
  @media print {
    text-decoration: none;
    color: var(--color-text);
  }
}

.social-media-list > li a > span {
  @media print {
    display: none;
  }
}

.social-media-list .username {
  display: none;
  @include for-size(tablet-landscape-up) {
    display: inline;
  }
}

.resume-wrapper .social-media-list .username {
  display: inline;
}

//.svg-icon {
//  width: 1em;
//  height: 1em;
//  display: inline-block;
//  fill: var(--color-text);
//  margin-inline-end: 0.6em;
//  vertical-align: text-top;
//}
