.tag {
  @extend %text--sm;
  text-transform: capitalize;
  display: inline-block;
  color: var(--color-pills-text);
  background-color: var(--color-pills-bg);
  min-width: 5ch;
  padding: 0.2em 0.5em;
  border-radius: 0.2rem;
}

a.tag {
  color: var(--color-pills-text);
  text-decoration-color: var(--color-pills-bg);
}

a.tag:hover {
  text-decoration-color: var(--color-pills-text);
}