//noinspection CssInvalidFunction
:root {
  /* main colors */
  --color-primary: #03A9F4;
  //--color-primary-light: color-mod(var(--color-primary) tint(15%));
  --color-primary-light: #63b8f6;
  //--color-primary-dark: color-mod(var(--color-primary) shade(15%));
  --color-primary-dark: #0397da;
  //--color-primary-bg: color-mod(var(--color-primary) alpha(20%));
  --color-primary-bg: #03a9f433;
  --color-primary-contrast: white;

  --color-accent: #aed581;
  --color-accent-light: #bcdc9b;
  --color-accent-dark: #97b870;
  --color-accent-bg:#aed58133;
  --color-accent-contrast: white;

  --color-body-bg: white;

  --black: #1d1d21;
  --gray-10: #2e2e31;
  --gray-6: #7b7a7d;
  --gray-4: #a5a5a6;
  --gray-3: #bbbbbc;
  --gray-2: #d1d0d2;
  --gray-1: #e8e7e8;
  --white: white;
}

:root {
  /* typography */
  --color-text: var(--gray-10);
  --color-text-heading: var(--black);
  --color-text-subtle: var(--gray-4);
  --color-link: var(--color-primary);
  --color-link-visited: var(--color-primary-dark);

  --color-pills-text: var(--white);
  --color-pills-bg: var(--color-primary-dark);
}

@media screen and (prefers-color-scheme: dark) {
  :root {
    // not supported yet, but soon!
    --color-text: var(--gray-1);
    --color-text-heading: var(--white);
    --color-text-subtle: var(--gray-3);
    --color-link: var(--color-accent);
    --color-link-visited: var(--color-accent-dark);
    --color-body-bg: var(--black);
    --color-primary-contrast: var(--black);
    --color-accent-contrast: var(--black);
    --color-pills-text: var(--black);
    --color-pills-bg: var(--color-accent-dark);
  }
}

.theme--dark {
  /*// not supported yet, but soon!*/
  --color-text: var(--gray-1);
  --color-text-heading: var(--white);
  --color-text-subtle: var(--gray-3);
  --color-link: var(--color-accent);
  --color-link-visited: var(--color-accent-dark);
  --color-body-bg: var(--black);
  --color-primary-contrast: var(--black);
  --color-accent-contrast: var(--black);
  --color-pills-text: var(--black);
  --color-pills-bg: var(--color-accent-dark);
}

.theme--dark {
  // what we can do for now
  color: var(--gray-1);
  background-color: var(--color-body-bg);

  h1, h2, h3, h4 {
    color: var(--white);
  }

  .text--subtle {
    color: var(--gray-4);
  }

  a {
    color: var(--color-accent);
  }

}
