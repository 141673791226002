.site-navigation {
  position: sticky;
  top: 0;
  padding: 0.75rem;
  z-index: 99;

  background-color: rgba(var(--color-body-bg), 0.5);
  backdrop-filter: blur(16px);
  @media print {
    display: none;
  }
}

.nav__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }



  @include for-size(tablet-portrait-up) {
    justify-content: flex-start;
  }
}

.nav__item {
  margin-inline: 1.5em;
  scroll-snap-align: start;
  flex-shrink: 0;
}

.nav__link {

}