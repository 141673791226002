$phone-upper-boundary: 37.5rem;
$tablet-portrait-upper-boundary: 56.25rem;
$tablet-landscape-upper-boundary: 75rem;
$desktop-upper-boundary: 112.5rem;

:root {
  --bp-phone-upper-boundary: 37.5rem;
  --bp-tablet-portrait-upper-boundary: 56.25rem;
  --bp-tablet-landscape-upper-boundary: 75rem;
  --bp-desktop-upper-boundary: 112.5rem;
}

@mixin for-size($range) {
  @if $range == phone-only {
    @media (max-width: #{$phone-upper-boundary - 1}) { @content; }
  } @else if $range == tablet-portrait-up {
    @media (min-width: $phone-upper-boundary) { @content; }
  } @else if $range == tablet-landscape-up {
    @media (min-width: $tablet-portrait-upper-boundary) { @content; }
  } @else if $range == desktop-up {
    @media (min-width: $tablet-landscape-upper-boundary) { @content; }
  } @else if $range == big-desktop-up {
    @media (min-width: $desktop-upper-boundary) { @content; }
  }
}

// usage
//.my-box {
//  padding: 10px;
//
//  @include for-size(desktop-up) {
//    padding: 20px;
//  }
//}
