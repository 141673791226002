//@import '../../../common/stylesheets/_breakpoints.scss';
//@import '../../../common/stylesheets/typography.scss';

.job-list__history {
  list-style: none;
  padding: 0;
}

.job-item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  @media print {
    page-break-inside: avoid;
  }
}

.job-item__dates {
  flex-basis: 20%;
  margin-inline-end: 1.5em;
  border-inline-end: 1px var(--color-text-subtle) solid;
  padding-inline-end: 0.6em;
  position: relative;

  @media print {
    flex-basis: 15%;
  }

  &:after {
    --width: 0.4em;
    --position: calc(1em * (1 / var(--body-line-height)));
    content: '';
    position: absolute;
    top: var(--position);
    right: calc((var(--position) * -1) - 1px);
    width: var(--width);
    height: var(--width);
    border-radius: 50%;
    background: var(--color-text);
    transform: translate(-50%, -50%);
    border: 3px solid var(--color-body-bg);
  }
}

.job-item__date {
  white-space: nowrap;
}

.job-item__details {
  flex: 1;
  padding-block-end: 2em;

  @media print {
    padding-block-end: 1em;
  }
}

.job-item__header, .job-item__info {
  width: 100%;
}

.job-item__header {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-block-end: 1em;

  @media print {
    margin-block-end: 0.5em;
  }

  &:last-child {
    margin-block-end: 0;
  }
}

.job-item__title {
  flex-basis: 100%;
  margin-block-end: 0.5em;
}

.job-item__location {
  flex-basis: 100%;
  white-space: nowrap;
  display: inline;
}

.location__icon {
  margin-inline-end: 0.3em;
}

.job-item__description--meta {
  @extend %text--sm;
  display: block;
  color: var(--color-text-subtle);
  font-style: italic;
}


@include for-size(tablet-portrait-up) {
  .job-item__header {
    flex-direction: row;
  }

  .job-item__title {
    flex-basis: 75%;
    margin-block-end: 0;
    margin-inline-end: 0.3em;
  }

  .job-item__location {
    flex-basis: 25%;
    justify-content: flex-end;
    text-align: right;
  }
}

@media print {
  .job-item__header {
    flex-direction: row;
  }

  .job-item__title {
    flex-basis: 75%;
    margin-block-end: 0;
    margin-inline-end: 0.3em;
  }

  .job-item__location {
    flex-basis: 25%;
    justify-content: flex-end;
    text-align: right;
  }
}
