.uses {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1em;
}

.used-item__header {
  text-transform: capitalize;
}

.used-item__list {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  //margin-block-start: 1em;
  //margin-block-end: 1em;
}
