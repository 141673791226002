* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  background-color: var(--color-body-bg);
  overscroll-behavior: none;
}

html {
  border-block-start: var(--color-primary) 1em solid;
  border-block-end: var(--color-accent) 1em solid;

  @media print {
    border-block-start-width: 0.5em;
    border-block-end-width: 0.5em;
  }

  min-height: 100vh;
}

.flow > * + * {
  margin-block-start: var(--flow-gap, 1em);
}

.wrapper, .resume-wrapper {
  padding: 0.75rem;

  @include for-size(tablet-landscape-up) {
    padding: 1.25rem;
  }

  @include for-size(desktop-up) {
    max-width: var(--bp-tablet-landscape-upper-boundary);
    margin: 0 auto;
  }

  @media print {
    padding: 0;
  }
}

//@include for-size(desktop-up) {
//  .wrapper {
//
//  }
//}