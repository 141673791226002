//Typography
@import "fonts";

:root {
  --font-primary: 'Ubuntu', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  --font-secondary: serif;
  --font-mono: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;

  /// * set base values * /
  --text-base-size: 1em;
  --text-scale-ratio: 1.2;
  --text-leading: calc(var(--text-base-size) + var(--text-scale-ratio));

  /// * type scale * /
  --text-xs: calc(1em / (var(--text-scale-ratio) * var(--text-scale-ratio)));
  --text-sm: calc(1em / var(--text-scale-ratio));
  --text-md: calc(1em * var(--text-scale-ratio));
  --text-lg: calc(1em * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-xl: calc(1em * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-xxl: calc(1em * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-xxxl: calc(1em * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio));

  /// * line-height * /
  --heading-line-height: 1.2;
  --body-line-height: 1.4;
}

@include for-size(tablet-portrait-up) {
  :root {
    --text-base-size: 1.25em;
    --text-scale-ratio: 1.25;
  }
}

@media print {
  :root {
    --text-base-size: 12px;
    --text-scale-ratio: 1.125;
  }
}

body {
  font-size: var(--text-base-size);
  font-family: var(--font-primary);
  color: var(--color-text);
  line-height: var(--body-line-height);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6, form legend {
  color: var(--color-text-heading);
  margin-block-end: var(--space-xxs);
  margin-block-start: 0.6em;
  line-height: var(--heading-line-height);
  font-weight: 300;
  text-wrap: balance;
}

///
//* text size *
//
///
.text--xxxl {
  font-size: var(--text-xxxl);
}

h1, .text--xxl {
  font-size: var(--text-xxl);
  letter-spacing: -.1rem;
}

h2, .text--xl {
  font-size: var(--text-xl);
  letter-spacing: -.1rem;
}

h3, .text--lg {
  font-size: var(--text-lg);
  letter-spacing: -.1rem;
}

h4, .text--md, %text--md {
  font-size: var(--text-md);
  letter-spacing: -.08rem;
}

h5, .text--sm, small, %text--sm {
  font-size: var(--text-sm);
  letter-spacing: -.05rem;
}

h6, .text--xs, %text--xs {
  font-size: var(--text-xs);
  letter-spacing: 0;
}

p {
  line-height: var(--body-line-height);
  max-width: 65ch;
  text-wrap: pretty;
}

a {
  color: var(--color-link);

  &:hover, &:visited {
    color: var(--color-link-visited);
  }
}

b, strong {
  font-weight: bold;
}

mark {
  padding: 0.1ch 0.25ch;
  background-color: var(--color-primary);
  @media screen and (prefers-color-scheme: dark) {
    background-color: var(--color-accent);
  }
}

u {
  text-decoration-color: var(--color-accent);
  @media screen and (prefers-color-scheme: dark) {
    text-decoration-color: var(--color-primary);
  }
}

.text-container {
  h2, h3, h4 {
    margin-top: var(--space-sm);
  }

  ul, ol, p {
    margin-bottom: var(--space-md);
  }

  ul, ol {
    list-style-position: outside;
    padding-left: 24px;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  ul li, ol li {
    line-height: var(--body-line-height);
  }

  em {
    font-style: italic;
  }

  u {
    text-decoration: underline;
  }
}

code {
  font-family: var(--font-mono);
}

///
//* utility classes *
//
///
.truncate {
  // truncate text if it exceeds parent
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.svg-icon {
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: var(--color-text);
  vertical-align: text-top;
}

blockquote {
  padding-left: 1em;
  border-left: 3px solid var(--color-pills-bg);
}